<template lang="pug">
  portal(to="toolbar-search")
    .search-widget(:class="{'search-widget--disabled': disabled}")
      .search-widget--desktop
        search(:value="value" :disabled="disabled" :placeholder="placeholder" @input="updateSearch")
      .search-widget--mobile
        slot(:open="open")
          app-button(icon iconLarge :disabled="disabled" :class="{'active': value}" @click.native="open")
            <i class="material-icons">search</i>

        app-dialog(v-model="showing" title="Search" width="500")
          template(#dialog-body)
            .search__wrapper
              app-input(v-model="search" :placeholder="placeholder" clearable)
          template(#dialog-footer)
            v-spacer
            app-button(color="#f4f4f4" hide-shadow @click.native="toggle").text-label.mr-2 Close
            app-button(color="primary" @click.native="updateDialogSearch") Search
</template>

<script>
import _ from 'lodash';
import showingMixin from '@/mixins/showing.mixin'
import appButton from '@/components/global/actions/BaseButton.vue'

export default {
  mixins: [showingMixin],

  props: {
    placeholder: { type: String, default: 'Search' },
    disabled: Boolean
  },

  data: () => ({
    search: undefined
  }),

  computed: {
    value() {
      return this.$route.query.search
    }
  },

  methods: {
    change() {
      this.$emit('change')
    },

    updateSearch: _.debounce(async function (value) {
      try {
        if (value === this.value) return
        await this.$router.push({ query: {...this.$route.query, page: 1, search: value || undefined }})
        this.change()
      } catch (error) {
        console.log(error)
      }
    }, 350),

    async updateDialogSearch() {
      await this.updateSearch(this.search)
      this.close()
    },

    open() {
      this.updateShowing(true)
    },

    close() {
      this.updateShowing(false)
    },

    reset() {
      this.search = undefined
    },

    prefill() {
      this.search = this.value;
    }
  },

  components: {
    appButton,
    search: () => import('@/components/global/SearchNew.vue'),
    appDialog: () => import('@/components/global/actions/BaseDialog.vue'),
    appInput: () => import('@/components/global/actions/BaseInput.vue'),
  },

  watch: {
    showing(value) {
      if (!value) this.reset()
      else this.prefill()
    }
  }
}
</script>

<style lang="scss" scoped>
.search-widget {
  padding-right: 10px;
  max-width: 400px;
  opacity: 1;
  transition: all 0.25s ease-in-out;

  &-- {
    &disabled {
      opacity: 0.5;
      transition: all 0.25s ease-in-out;
    }
    &desktop {
      display: flex;

      @include media("<=460px") {
        display: none;
      }
    }
    &mobile {
      display: none;
      justify-content: flex-end;

      @include media("<=460px") {
        display: flex;
      }
    }
  }
}

.search__wrapper {
  padding: 10px;
}

</style>

<style lang="scss">
.app-button.active {
  .app-button__wrapper .material-icons {
    transform-origin: 0 0;
    animation: scales 1s ease-in-out infinite alternate;
  }
}

@keyframes scales {
  0% {
    transform: translate(0) rotate(0) scale(1.0);
  }
  100% {
    transform: translate(7px, -5px) rotate(35deg) scale(1.05);
  }
}

</style>
